@import "../../static/styles/variables";

.panel {
  background-color: #fff;
  border-radius: 10px;
  color: #0a688d;
  margin-bottom: 20px;
  padding: 0 20px;
  box-sizing: border-box;
  overflow: hidden;
}

.title {
  box-sizing: border-box;
  padding-bottom: 15px;
  padding-top: 15px;
  cursor: pointer;
  font-size: 15px;
  display: block;
  font-weight: 500;
  line-height: 1.5;
  position: relative;
  padding-right: 20px;
  text-transform: uppercase;
  width: 100%;
  z-index: 2;
}

.label {
  font-size: 18px;
  color: #a0a0a0;
  font-weight: 300;
  margin-top: -0.15em;
}

.indicator {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.content {
  transition: max-height 500ms $easeOutQuart;
}

.contentWrap {
  padding-bottom: 15px;
}

.backLinkWrap {
  display: inline-block;
  color: $color-primary-1;
}

.backLink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.75em;
    margin-top: -2px;
  }
}

@media screen and (min-width: 600px) {
  .panel {
    padding: 0 40px;
  }

  .title {
    padding: 30px 40px 30px 20px;
    margin: 0 -20px;
    font-size: 16px;
  }

  .contentWrap {
    padding-bottom: 22px;
  }

  .label {
    font-weight: 900;
    font-size: 1.25em;
    color: inherit;
    float: right;
    margin-top: -0.125em;
  }
}
