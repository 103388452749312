@import "../../static/styles/variables";

.errorLogin {
  background-color: $color-secondary-dark-2;
  color: white;
  margin-bottom: 20px;
  padding: 15px 0;
}

.container {
  @include container;
}

.label {
  max-width: 80%;
  text-align: left;
  line-height: 1.25;
  font-weight: 300;
}

.grid {
  align-items: center;
  display: flex;
  height: 83px;
  font-size: 14px;
  justify-content: center;
  position: relative;

  > p {
    height: 100%;
  }
}
