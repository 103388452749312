@import '../../static/styles/variables';

.overlay {
  background: rgba(darken($color-primary, 10%), 0.35);
  cursor: pointer;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.popup {
  cursor: auto;
  padding: 2.5em 3em;
  background-color: white;
  border-radius: 10px;
  transform: translateY(-50%) translateX(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  max-width: 400px;
  box-sizing: border-box;
  color: $color-secondary-dark;
  text-align: left;
}

.title {
  color: $color-secondary-dark-2;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0.75em;
}

.content {
  font-weight: 300;
}
