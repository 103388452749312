@import "../static/styles/variables";

.container {
  @include containerLarge;
  margin-top: 30px;
}

.errorContent {
  margin-top: 15px;
}
