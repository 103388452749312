@import "../../static/styles/variables";

@keyframes slideDownWarning {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 100px;
  }
}

.panel {
  background-color: #ff0042;
  color: white;
  overflow: hidden;
}

.wrap {
  height: 83px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  color: white;
  text-decoration: none;

  strong {
    font-weight: 900;
  }

  span {
    padding-right: 10px;
  }
}

.wrapContent {
  display: flex;
  line-height: 1.25;
  align-items: center;
}

.container {
  @include containerLarge;
}

@media screen and (min-width: 600px) {
  .icon {
    width: 134px;
  }

  .wrap {
    height: 103px;
  }

  .wrapContent {
    font-size: 1.2em;

    > span {
      max-width: 300px;
    }
  }
}
