@import "../../static/styles/variables";

.field {
  font-size: 15px;
  font-weight: 300;
  font-family: $font-primary;
  padding: 15px 10px;
  border: 0;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

:global(.DayPickerInput-OverlayWrapper) {
  position: absolute;
  z-index: 50;
}

@media screen and (min-width: 600px) {
  .field {
    padding: 16px 10px;
  }
}
