@import '../../static/styles/variables';

.input {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  color: $color-primary-dark;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 300;
  padding: 15px 10px;
  width: 100%;

  &::placeholder {
    transition: opacity 0.12s ease-in;
  }

  &:focus {
    outline: none;
    &::placeholder {
      opacity: 0.25;
    }
  }
}

.inputContainer {
  position: relative;
}

.withIcon {
  .input {
    padding: 15px 42px;
  }

  svg {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 600px) {
  .input {
    padding: 18px 10px;
  }
}
