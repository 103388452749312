.icon {
  background-color: rgba(0, 0, 0, 0.1);
  height: 100%;
  position: relative;
  text-align: center;
  width: 75px;
  display: flex;
  align-items: center;

  > svg {
    margin: 0 auto;
  }

  @media screen and (min-width: 600px) {
    width: 134px;

    > svg {
      font-size: 55px !important;
    }
  }
}
