.row {
  display: flex;
  justify-content: space-between;
}

.input {
  flex-grow: 1;
  margin-right: 10px;

  input {
    border: 1px solid #ccc;
  }
}

.button {
  button {
    padding: 18px 15px 19px;
  }
}
