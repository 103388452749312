.listItem {
  color: #485566;
  font-weight: 300;
  line-height: 1.25em;
  margin: 0 -15px;
  padding: 10px 15px 10px 35px;
  position: relative;

  &::before {
    background-color: #485566;
    border-radius: 50%;
    content: "";
    height: 5px;
    left: 18px;
    position: absolute;
    top: 18px;
    width: 5px;
  }

  strong,
  h3 {
    font-weight: 500;
  }

  &:nth-child(odd) {
    background-color: #f7f7f7;
  }

  &:nth-child(even) {
    background-color: #fff;
  }

  @media screen and (min-width: 600px) {
    margin: 0 -35px;
    padding: 20px 30px 20px 50px;

    &::before {
      left: 35px;
      top: 27px;
    }
  }
}
