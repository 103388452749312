@import "../../static/styles/variables";

.footerPanel {
  background-color: #0677a2;
  padding: 30px 0 30px;
}

.container {
  @include containerLarge;
}

.quote {
  color: white;
  margin: 0 auto;
  text-align: right;
  width: 80%;

  h3 {
    font-size: 20px;
    font-weight: 500;
  }

  h4 {
    font-size: 16px;
    font-weight: 300;
    margin: 13px 0 0;
  }
}
