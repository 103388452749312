@import "../../static/styles/variables";

.sectionTitle {
  padding-bottom: 20px;
  padding-top: 20px;
  color: white;
  font-weight: 300;
  font-size: 15px;

  strong {
    margin-top: 10px;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
  }
}

.panelLoading {
  text-align: center;
}

.container {
  @include containerLarge;
}

.panelsWrap {
  margin: 0 -10px;
  min-height: 70vh;
}

@media screen and (min-width: 600px) {
  .sectionTitle {
    margin: 20px 0;
    font-size: 16px;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    strong {
      margin-top: 0;
    }
  }

  .panelsWrap {
    padding-bottom: 30px;
  }
}
