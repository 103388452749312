@import '../../static/styles/variables';

.button {
  @include buttonReset;
  background-color: $color-secondary-dark;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  font-weight: 700;
  padding: 15px 10px;
  text-transform: uppercase;
  width: 100%;
  transition: background-color 0.1s ease-in;

  &:hover {
    background-color: $color-secondary-dark-hover;
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: darken($color-secondary-dark, 5%);
    position: relative;
    top: 1px;
    transition: none;
  }
}
