@import "../../static/styles/variables";

.loadingPanel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.9);
  background-position: center center;
  background-image: url("/static/images/loading.svg");
  animation: fadeIn 0.5s $easeOutQuart;
  z-index: 5;
}
