@import "../../static/styles/variables";

.container {
  @include containerLarge;
  margin-top: 30px;
}

.header {
  margin: 0 -20px;
  position: relative;
}

.headerText {
  background-color: #3b4759;
  color: white;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.customerInfoPanel {
  background-color: white;
  overflow: hidden;
  padding: 0 20px 30px;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
}

.contactContainerFloating {
  padding: 15px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 6px;

  ul {
    margin: 0 auto;
  }
}

.messageContainer {
  margin-top: 20px;
}

.contactContainer {
  padding: 20px 0;
  ul {
    margin: 0 auto;
  }
}

.infos {
  margin-top: 22px;
  padding-bottom: 10px;

  > div {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.footer {
  background-color: #172231;
  color: #fff;
  text-align: center;
  margin: 30px -20px -15px;
  padding: 15px 0 0;
  text-transform: uppercase;
}

.footerCta {
  padding-bottom: 1em;
  border-bottom: 1px solid #2d3a49;
}

.subTitle {
  border-bottom: 1px solid $color-primary-2;
  color: #626060;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0.75em;
  padding-bottom: 0.5em;
  letter-spacing: 0.025em;
}

.clientSummaryWrap,
.moreDetailsWrap {
  margin-top: 45px;
  font-family: $font-secondary;
}

@media screen and (min-width: 600px) {
  .header {
    margin: 0 -40px;
  }

  .reason {
    padding-left: 40px;
  }

  .footer {
    margin: 1.5em -40px -22px;
    padding: 0;
  }

  .footerCta {
    padding: 1em 0;
    font-size: 20px;
  }

  .reasonText {
    font-size: 16px;
    padding: 25px 40px;
  }

  .messageContainer {
    margin-top: 30px;
  }

  .contactContainerFloating {
    top: 26px;
  }

  .infos {
    > div {
      margin-bottom: 20px;
    }
  }
}
