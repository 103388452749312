.label {
  display: block;
  margin-bottom: 0.25em;
}

.buttonContainer {
  > button {
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 520px) {
  .buttonContainer {
    > button {
      margin-bottom: 10px;
      margin-left: 0;
      width: 100%;
    }
  }
}
