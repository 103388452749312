.table {
  color: #1eb4a1;
  font-size: 16px;

  td {
    padding: 0 5px;
  }
  
  td:last-child {
    width: 150px;
    font-weight: 900;
  }

  tr:nth-child(odd) {
    background: #f6f6f6;
  }

  .header:nth-child(1),
  tr:nth-child(even) {
    background: #ececec;
  }

  .header td:last-child {
    font-weight: normal;
  }

  @media screen and (min-width: 600px) {
    td {
      font-size: 18px;
      line-height: 1.25;
      padding-bottom: 0.25em;
      padding-top: 0.25em;
      text-align: left;
    }

    td:last-child {
      text-align: center;
    }
  }
}
