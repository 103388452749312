@import "../../static/styles/variables";

.row {
  margin-bottom: 2em;
}

.label {
  display: block;
  margin-bottom: 0.5em;
}

.additionalField {
  margin-top: 0.5em;
}

.fieldsContainer {
  padding-left: 0.5em;
  border-left: 5px solid rgba($color-primary, 0.5);
}
