@import "../static/styles/variables";

.pageContainer {
  @include containerLarge;
  margin-top: 30px;
}

.errors {
  padding: 1em 0;
  color: red;
}

.error {
  margin-bottom: 0.5em;
}
