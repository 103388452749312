@import '../../static/styles/variables';

.container {
  @include containerLarge;
  margin-top: 30px;
}

.header {
  margin: 0 -20px;
}

.status {
  align-items: center;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  color: $color-primary;
  display: flex;
  margin: 0 -20px;
  padding: 1.25em 20px;
  font-size: 20px;
  font-weight: 300;
  justify-content: space-between;
}

.option {
  align-items: center;
  color: $color-secondary-dark-1;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  font-weight: 300;
  position: relative;

  &:before {
    background-color: $color-primary-4;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 20px;
    margin-right: 10px;
    width: 20px;
  }
}

.checked {
  &::after {
    background-color: white;
    border-radius: 50%;
    content: '';
    height: 9px;
    left: 5px;
    position: absolute;
    top: 5px;
    width: 9px;
  }
}

@media screen and (min-width: 600px) {
  .header {
    margin: 0 -40px;
  }
}
