@import "../../static/styles/variables";

.row {
  margin-bottom: 1em;
}

.title {
  margin-top: 3em;
  margin-bottom: 2em;
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 1em;
  border-bottom: 1px solid rgba($color-primary, 0.5);
}

.error {
  @include errorLabel();
}
