@import '../../static/styles/variables';

.button {
  @include buttonReset;
  background-color: $color-primary-4;
  color: white;
  font-size: 14px;
  font-weight: 700;
  padding: 15px 15px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: color 150ms ease-in;

  &[disabled] {
    cursor: auto;
  }
}

.loading {
  color: transparent !important;

  .loaderContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  svg {
    animation: spin 1s $easeOutQuad infinite;
  }

  &:before {
    background: rgba(255, 255, 255, 0.1);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

@media screen and (min-width: 600px) {
  .button {
    font-size: 18px;
  }
}
