@import "../../static/styles/variables";

.header {
  background-color: #0a658c;
  margin-bottom: 0;

  h1 {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    line-height: normal;
  }

  > div {
    div {
      // ícone
      height: 90px;
    }
  }
}

.wrap {
  align-items: center;
  display: flex;
  height: 90px;
  justify-content: flex-start;

  strong {
    font-weight: 900;
  }
}

.container {
  @include containerLarge;
}

@media screen and (min-width: 600px) {
  .header {
    margin-bottom: 0;

    h1 {
      font-size: 25px;
    }

    > div {
      div {
        // ícone
        height: 135px;
      }
    }
  }

  .wrap {
    height: 134px;
  }
}
