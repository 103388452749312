@import '../static/styles/variables';

.container {
  @include containerLarge;
  margin-top: 30px;

  input {
    border: 1px solid #ccc;
  }
}

.error,
.errorNotFound {
  @include errorLabel;
}

.textarea {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  color: $color-primary-dark;
  font-family: $font-primary;
  font-size: 18px;
  font-weight: 300;
  height: 300px;
  padding: 15px 10px;
  width: 100%;
  margin-top: 0.5em;

  &::placeholder {
    opacity: 1;
    transition: 150ms ease-in opacity;
  }

  &:focus {
    outline: none;
    &::placeholder {
      opacity: 0.5;
    }
  }
}

.panel {
  margin: 1.5em 0 0.5em;

  dt {
    color: #aaa;
    text-transform: uppercase;
    font-size: 0.8em;
  }

  dd {
    color: #999;
    font-size: 1.2em;
    font-weight: 300;
  }
}

.button {
  margin-top: 0.5em;
}
