.commentTitle {
  color: #626060;
  margin-bottom: 0.25em;
  font-size: 14px;
  font-weight: 600;
}

.comment {
  color: #626060;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 1.5em;

  &:last-child {
    margin-bottom: 0;
  }
}
