.item {
  display: block;
  padding: 20px;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0 -20px;
  text-decoration: none;
  color: inherit;
  border-bottom: 1px solid #c5c8cb;
  border-left: 8px solid #000;
  font-weight: 300;
  position: relative;

  &:first-child {
    border-top: 1px solid #c5c8cb;
  }

  &:hover {
    .arrow {
      right: 19px;
    }
  }
}

.client {
  opacity: 0.8;
}

.arrow {
  position: absolute;
  right: 22px;
  top: calc(50% - 0.5em);
  transition: right 150ms ease-out;
}

@media screen and (min-width: 600px) {
  .item {
    margin: 0 -40px;
    padding: 23px 40px 23px 34px;

    &:hover {
      .arrow {
        right: 60px;
      }
    }
  }

  .arrow {
    right: 62px;
  }
}
