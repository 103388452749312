@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

@keyframes loading {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

.badgeContainer {
  padding: 0 20px;
  position: relative;
  font-family: "Open Sans"; }

.icon {
  position: absolute;
  top: 25px;
  left: 40px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #ff0042; }
  .icon svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%); }

.badge {
  background: #f9f8f8;
  border: 1px solid #d0caca;
  color: #626060;
  font-weight: 200;
  padding: 2em 2.5em 2em 6em;
  font-size: 14px;
  line-height: 1.35; }
  .badge h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0.25em; }
  .badge::before {
    content: '';
    width: 15px;
    height: 15px;
    display: inline-block;
    position: absolute;
    top: -8px;
    left: 52px;
    /*transform: rotateZ(45deg);
    background: #f9f8f8;
    border-left: 1px solid #d0caca;
    border-top: 1px solid #d0caca;*/ }

@media screen and (min-width: 600px) {
  .badgeContainer {
    padding: 0 40px; }
  .icon {
    left: 60px; } }
