@import "../../static/styles/variables";

.select {
  appearance: none;
  -webkit-appearance: none;
  line-height: normal;
  position: relative;
  width: 100%;
  height: 50px;
  border: 1px solid #cbcfd2;
  border-radius: 5px;
  padding: 0 15px;

  &[disabled] {
    opacity: 0.5;
  }

  &,
  option {
    font-family: $font-primary;
    font-size: 18px;
    color: #085a50;
    font-weight: 300;
  }
}

.arrow {
  position: absolute;
  right: 15px;
  top: calc(50% - 8px);
  pointer-events: none;
  width: 20px;
}

.selectBoxContainer {
  position: relative;
}

@media screen and (min-width: 600px) {
  .select {
    height: 60px;
  }
}
