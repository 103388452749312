@import "../../static/styles/variables";

.menu {
  background-color: $color-primary-1;
  border-bottom: 4px solid $color-primary-2;
  padding: 20px 0;
  position: relative;
  z-index: 20;
}

.container {
  @include containerLarge;
}

.grid {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.buttonMenu {
  @include buttonReset;

  background: 0 none;
  cursor: pointer;
  height: 30px;
  margin: 0;
  position: relative;
  text-indent: -9999px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  width: 30px;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #031927;
    border-radius: 10px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      width: 16px;
      left: auto;
      right: 0;
      top: 12px;
    }

    &:nth-child(4) {
      top: 24px;
    }
  }
}

.navContainer {
  display: none;
  height: calc(100vh - 116px);
  overflow: auto;
  position: absolute;
  right: 0;
  text-align: left;
  top: 85px;
  width: 100%;

  a {
    text-decoration: none;
    background-color: $color-secondary-dark-1;
    color: $color-primary;
    display: block;
    font-size: 20px;
    padding: 20px;
    font-weight: 300;
    transition: background-color 0.3s;

    &:hover {
      background-color: #141d2b;
    }
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: $color-secondary-dark-1;
}

.menuOpened {
  span {
    &:nth-child(1) {
      top: 18px;
      width: 0%;
      left: 50%;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
      width: 100%;
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
      width: 100%;
    }

    &:nth-child(4) {
      top: 18px;
      width: 0%;
      left: 50%;
    }
  }

  .navContainer {
    display: block;
  }
}

h1 {
  img {
    max-width: 70%;
    width: 275px;
    height: auto;
  }
}

@media screen and (min-width: 600px) {
  .menu {
    padding: 35px 0;
  }

  h1 {
    img {
      width: 320px;
    }
  }

  .buttonMenu {
    width: 45px;

    span {
      height: 4px;

      &:nth-child(2) {
        width: 25px;
      }
    }
  }

  .menuOpened {
    span {
      &:nth-child(2) {
        width: 100%;
      }
    }
  }
}
