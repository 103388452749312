@import '../../static/styles/variables';

.container {
  @include containerLarge;
  margin-top: 30px;

  input {
    border: 1px solid #ccc;
  }
}

.error,
.errorNotFound {
  @include errorLabel;
}
