.chartPanel {
  background-color: #f2f9f8;
  width: 100%;
}

.barContainer {
  width: 100%;
  margin-bottom: 30px;
  max-width: 75%;
  align-items: flex-end;
}

@media screen and (min-width: 768px) {
  .chartPanel {
    display: flex;
    align-items: flex-end;
    height: 300px;
  }

  .barContainer {
    align-items: flex-end;
    display: flex;
    height: 100%;
    margin-bottom: 0;
    margin-right: 15px;
    max-width: 100%;
    max-height: 80%;

    &:last-child {
      margin-right: 0;
    }
  }
}
