.icon {
  align-items: center;
  background-color: #fff700;
  display: flex;
  font-size: 15px;
  height: 30px;
  line-height: 25px;
  margin-right: 10px;
  position: relative;
  text-align: center;
  width: 35px;

  > svg {
    margin: 0 auto;
  }

  &::after {
    border-top: 12px solid #fff700;
    border-left: 12px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    top: calc(100% - 1px);
    right: 5px;
    width: 0;
  }

  @media screen and (min-width: 600px) {
    width: 42px;
    height: 40px;
    margin-right: 22px;
  }
}
