@import '../../static/styles/variables';

.container {
  background: $color-secondary-dark-1;
  padding: 25px 20px;
  border-top: 1px solid #f0f0f0;
}

.name {
  color: white;
  font-weight: 300;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  max-width: 60%;
  line-height: 1.25em;
  text-decoration: none;

  svg {
    margin-right: 0.75em;
  }

  @media screen and (min-width: 600px) {
    font-size: 20px;
    font-weight: 400;
    padding: 20px 18px;
  }
}
