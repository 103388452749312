@import '../../static/styles/variables';

.itemContainer {
  margin-bottom: 22px;
  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  color: rgb(143, 143, 148);
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.25em;
}

.content {
  color: $color-primary-3;
  font-size: 20px;
  display: block;
  font-weight: 300;
}

.itemContainer {
  display: block;
}

@media screen and (min-width: 600px) {
  .itemContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }

  .title {
    margin-bottom: 0;
    width: 230px;
  }

  .content {
    font-size: 15px;
  }
}
