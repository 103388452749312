.header {
  font-size: 20px;
  background-color: rgba(46, 188, 170, 0.1);
  color: #3b4759;
  margin: 0 -15px;
  padding: 25px 15px;
  font-weight: 300;
  margin-bottom: 25px;
  margin-top: -20px;
}

%tableHeader {
  font-size: 0.8em;
  font-weight: 600;
}

.headerLabel {
  @extend %tableHeader;
  display: none;
}

.mobileItem {
  margin-bottom: 1.5em;

  &:last-child {
    margin-bottom: 0;
  }
}

.mobileItemContent {
  color: #3b4759;
  font-weight: 300;
  margin-top: 0.25em;

  div {
    margin: 0;
    padding: 0;
  }
}

.contactItem {
  margin: 0 -0.9em;
  padding: 1em 1em;
  overflow-x: auto;

  &:nth-child(2n) {
    background-color: #f9f9f9;
  }

  &:first-child {
    margin-top: -20px;
  }

  &:last-child {
    margin-bottom: -20px;
  }
}

.contactItemWrapDesktop {
  min-width: 500px;

  tr:nth-child(n) {
    background-color: transparent;
  }

  td,
  th {
    padding: 5px 18px;
    height: auto;
    vertical-align: top;
  }

  th:first-child,
  td:first-child {
    padding-left: 0;
  }

  td,
  td:first-child {
    color: #8f8f94;
    font-size: 0.85em;
    font-weight: 300;
  }

  th,
  th:first-child {
    font-size: 0.7em;
    font-weight: 700;
  }

  td > div {
    padding: 0;
  }
}

.agendaColumn {
  width: 60%;
}

.dateColumn {
  width: 15%;
}

.anotacaoColumn {
  width: 100%;
}

.anotacaoColumnTitle {
  padding-top: 10px !important;
}

@media screen and (min-width: 600px) {
  .header {
    padding: 25px 35px;
    margin: 0;
  }

  .midiaColumn {
    text-align: center;
  }

  .contactItem {
    margin: 0 -2.2em;
    padding: 1.5em 2.2em;
  }

  .headerLabel {
    display: block;
  }
}

.contactItemMobile {
  .header {
    font-size: 20px;
    background-color: rgba(46, 188, 170, 0.1);
    color: #3b4759;
    margin: 0 -15px;
    padding: 25px 15px;
    font-weight: 300;
    margin-bottom: 25px;
    margin-top: -20px;
  }

  @media screen and (min-width: 600px) {
    .header {
      margin: -26px -35px 20px;
      padding: 25px 35px;
    }
  }
}
