@import "../../static/styles/variables";

.title {
  display: flex;
  width: 100%;
  height: 90px;
  align-items: center;
  flex-wrap: wrap;

  > div {
    margin-right: 20px;
    font-weight: 300;
  }
}

.container {
  @include containerLarge;
  margin-top: 20px;
}

.panelsContainer {
  margin: 10px -20px 0;
}

@media screen and (min-width: 600px) {
  .panelsContainer {
    margin: 20px -40px 0;
  }
}
