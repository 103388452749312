@import "../../static/styles/variables";

.container {
  font-size: 15px;
  color: white;
}

.spin {
  svg {
    @include spinAnimation;
  }

  span {
    color: white;
    margin-left: 10px;
  }
}
