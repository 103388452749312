@import '../../static/styles/variables';

.listContainer {
  display: flex;
  width: 100px;
  flex-wrap: wrap;
  justify-content: space-around;
}

.customerPhone {
  color: $color-primary-3;
  cursor: pointer;
  margin-bottom: 0.75em;
  font-weight: 300;
}

.successMessage {
  text-align: center;
  padding: 1.25em 0;
  font-weight: 300;
}

.error {
  @include errorLabel;
  text-align: center;
}

.badge {
  background-color: #ebebeb;
  border-radius: 50%;
  cursor: pointer;
  height: 32px;
  position: relative;
  text-align: center;
  width: 32px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

.whatsapp {
  background-color: rgb(70, 167, 96);
}

.phone {
  background-color: #f4564d;
}

.email {
  background-color: #fcd161;
}

.comment {
  background-color: #4b65ba;
}

.disabled {
  background-color: #ebebeb;
  cursor: not-allowed;
}

.smsConfirmationText {
  background-color: rgba($color-primary, 0.15);
  border-radius: 5px;
  padding: 1.5em;
  margin: 1em 0 1em;
  line-height: 1.25;
}

.cancelSMS {
  background-color: rgba($color-secondary-dark-1, 0.35);
  margin-top: 0.25em;

  &:hover {
    background-color: rgba($color-secondary-dark-1, 0.3);
  }
}

@media screen and (min-width: 600px) {
  .contactPanelContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
  }

  .customerPhone {
    font-size: 20px;
    margin-bottom: 0;
  }
}
