@import "../../static/styles/variables";

.bar {
  max-height: 120px;
  box-sizing: border-box;
  min-width: 120px;
  width: 120px;
  transition: width 1s $easeOutQuad;
}

.barTop {
  align-items: center;
  background-color: #171a31;
  border-top: 4px solid $color-primary;
  box-sizing: border-box;
  display: flex;
  height: 70px;
  min-height: 40px;
  padding: 12px 0;
  position: relative;
  text-align: center;

  span {
    color: white;
    font-size: 20px;
    font-weight: 300;
    margin: 0 auto;
  }

  &::before {
    border-left: 11.5px solid transparent;
    border-right: 11.5px solid transparent;
    border-top: 13px solid $color-primary;
    content: "";
    height: 0;
    left: 50%;
    margin-left: -11.5px;
    position: absolute;
    top: 0;
    width: 0;
  }
}

.label {
  background-color: #2d303d;
  color: white;
  font-size: 16px;
  padding: 15px 0;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .bar {
    max-height: none;
    min-height: 100px;
    transition: height 1s $easeOutQuad;
    width: 100% !important;
  }

  .barTop {
    height: calc(100% - 46px);
  }
}
