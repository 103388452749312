@import "../../static/styles/variables";

.productPanel {
  text-align: left;
  margin-top: 30px;

  h2 {
    font-size: 18px;
    font-weight: 300;
    text-align: left;
  }

  h3 {
    background-color: $color-primary-4;
    color: white;
    padding: 2em 1.5em;

    text-align: left;
    font-size: 16px;
    text-transform: uppercase;
  }
}

.products {
  margin-top: 30px;
}

.panel {
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #eaeaea;
  border-top: 0;

  label {
    display: block;
    margin-bottom: 0.5em;
  }

  &:nth-child(1) {
    border-top: 1px solid #eaeaea;
  }
}

.add {
  border: 1px solid #eaeaea;
  border-top: 0;
  margin-bottom: 15px;
  color: $color-primary-1;
  cursor: pointer;
  font-size: 13px;
  font-weight: 300;
  padding: 20px;
  text-transform: uppercase;

  svg {
    margin-right: 0.5em;
  }
}

@media screen and (min-width: 600px) {
  .add {
    font-size: 18px;
    font-weight: 300;
    padding: 30px 20px;
    text-transform: none;
  }
}
