@import "../../static/styles/variables";

.item {
  display: block;
  font-weight: 300;
  line-height: 1.3;
  padding: 0;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #c5c8cb;
  border-top: 0;
  border-left: 5px solid $color-secondary-dark-1;

  &:nth-child(1) {
    border-top: 1px solid #c5c8cb;
  }

  :global(.Collapsible__contentOuter) {
    transition-timing-function: $easeOutQuad !important;
  }

  :global(.Collapsible__trigger) {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    width: 100%;
  }

  :global(.is-closed) {
    .closeIcon {
      display: none;
    }

    .openIcon {
      display: block;
    }
  }

  :global(.is-open) {
    .openIcon {
      display: none;
    }

    .closeIcon {
      display: block;
    }
  }

  :global(.Collapsible__contentInner) {
    background-color: #f6f6f6;
    padding: 20px 20px;
    box-sizing: border-box;
  }

  :global(.DayPickerInput) {
    width: 100%;
  }

  @media screen and (min-width: 600px) {
    font-size: 16px;
    border-left-width: 8px;

    :global(.Collapsible__trigger) {
      padding: 1.5em 2em;
    }

    :global(.Collapsible__contentInner) {
      padding: 30px 35px;
    }
  }
}
