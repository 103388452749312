@import "../../static/styles/variables";

.accordion {
  :global(.Collapsible__trigger) {
    background-color: $color-primary-3;
    color: white;
    cursor: pointer;
    display: flex;
    width: 100%;
    text-transform: uppercase;
    box-sizing: border-box;
    padding: 20px;
    position: relative;

    div {
      max-width: 90%;
    }
  }

  :global(.Collapsible__contentOuter) {
    transition-timing-function: $easeOutQuad !important;
  }

  :global(.Collapsible__contentInner) {
    padding: 20px 15px;
    border: 1px solid $color-primary-3;
  }

  :global(.is-closed) .iconMinus {
    display: none;
  }

  :global(.is-open) .iconPlus {
    display: none;
  }

  @media screen and (min-width: 600px) {
    :global(.Collapsible__trigger) {
      font-size: 20px;
      padding: 25px 35px;
    }

    :global(.Collapsible__contentInner) {
      padding: 20px 35px;
    }
  }
}

.icon {
  position: absolute;
  right: 20px;
  top: calc(50%);
  transform: translateY(-50%);
}
