.logo {
  display: block;
  margin: 40px 0 60px;

  img {
    max-width: 70%;
    height: auto;
    margin: 0 auto;
    display: block;
  }
}

.intro {
  color: #fff;
  margin-bottom: 2em;
}

.pageContainer {
  padding-bottom: 30px;
}
