.purchase:first-child {
  margin-top: -20px;
}

.header {
  font-size: 20px;
  background-color: rgba(46, 188, 170, 0.1);
  color: #3b4759;
  margin: 0 -15px;
  padding: 25px 15px;
  font-weight: 300;
  margin-bottom: 20px;
}

.itemList {
  margin-top: 2px;
}

@media screen and (min-width: 600px) {
  .header {
    margin: 0 -35px 25px;
    padding: 25px 35px;
  }

  .itemList {
    margin-top: 2px;
  }
}
