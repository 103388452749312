@import '../../static/styles/variables';

.badgeContainer {
  padding: 0 20px;
  position: relative;
  font-family: $font-secondary;
}

.icon {
  position: absolute;
  top: 25px;
  left: 40px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #ffde80;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

.badge {
  background: #f9f8f8;
  border: 1px solid #d0caca;
  color: #626060;
  font-weight: 200;
  padding: 2em 2.5em 2em 6em;
  font-size: 14px;
  line-height: 1.35;

  h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0.25em;
  }

  &::before {
    content: '';
    width: 15px;
    height: 15px;
    display: inline-block;
    position: absolute;
    top: -8px;
    left: 52px;
    /*transform: rotateZ(45deg);
    background: #f9f8f8;
    border-left: 1px solid #d0caca;
    border-top: 1px solid #d0caca;*/
  }
}

@media screen and (min-width: 600px) {
  .badgeContainer {
    padding: 0 40px;
  }

  .icon {
    left: 60px;
  }
}
