@import "../../static/styles/variables";

.panel {
  padding: 20px 0 0;

  h3 {
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    color: $color-primary;
  }
}

.grid {
  display: flex;
  justify-content: space-between;
  max-width: 250px;
  height: 64px;
  margin: 40px auto 10px;
}

.rate {
  background-image: url("/static/images/rating-320.png");
  background-repeat: no-repeat;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  width: 42px;

  &:nth-child(1) {
    background-position: 0 0;
  }

  &:nth-child(2) {
    background-position: -42px 0;
  }

  &:nth-child(3) {
    background-position: -83px 0;
  }

  &:nth-child(4) {
    background-position: -125px 0;
  }
}

.rateSelected {
  background-image: url("/static/images/rating-320.png");
  background-repeat: no-repeat;
  transform: scale(1.35);
  transition: 160ms $easeOutQuad transform;
}

.scale {
  height: 19px;
  width: 270px;
  margin: 0 auto;
  background-image: url("/static/images/scale-320.png");
}

.scale0 {
  background-position: 0 0;
}

.scale1 {
  background-position: 0 -20px;
}

.scale2 {
  background-position: 0 -40px;
}

.scale3 {
  background-position: 0 -60px;
}

.scale4 {
  background-position: 0 -80px;
}

.label {
  width: 280px;
  margin: 20px auto 30px;
  height: 50px;
  color: $color-secondary-dark-1;
  font-weight: 300;
  display: flex;
  position: relative;
}

.labelScale {
  font-size: 16px;
  left: 0;
  line-height: 1.25;
  opacity: 0;
  padding-top: 15px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100px;
  margin-left: -1em;
  transition: opacity 60ms ease-in;

  &:before {
    border-bottom: 12px solid #172231;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: "";
    display: block;
    height: 0;
    left: calc(50% - 5px);
    position: absolute;
    top: -5px;
    width: 0;
  }

  &:nth-child(1) {
    left: 0;
  }

  &:nth-child(2) {
    left: 25%;
  }

  &:nth-child(3) {
    left: 50%;
  }

  &:nth-child(4) {
    left: 75%;
  }
}

.labelScaleSelected {
  opacity: 1;
}

.title {
  background-color: #ff0042;
  margin: 0 -20px;
  padding: 0 20px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: white;
    width: 80%;
  }
}

.notes {
  margin: 0 -20px -20px;
  background-color: #f6f6f6;
  padding: 20px;
}

.textarea {
  border-radius: 20px;
  border: 0;
  box-sizing: border-box;
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 300;
  height: 290px;
  line-height: 1.25;
  padding: 20px;
  width: 100%;

  &::placeholder {
    transition: opacity 100ms ease-in;
  }

  &:focus {
    outline: none;

    &::placeholder {
      opacity: 0.25;
    }
  }
}

.button {
  @include buttonReset;
  background-color: $color-secondary-dark-1;
  color: white;
  font-size: 15px;
  font-weight: 700;
  border-radius: 5px;
  padding: 1em 0;
  margin-top: 10px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.error {
  font-size: 12px;
  color: red;
  font-weight: 300;
  margin-top: 1em;
  margin-bottom: 0.25em;
}

@media screen and (min-width: 600px) {
  .grid {
    max-width: 320px;
  }

  .rate {
    background-image: url("/static/images/rating.png");
    height: 53px;
    width: 53px;

    &:nth-child(1) {
      background-position: 0 0;
    }

    &:nth-child(2) {
      background-position: -54px 0;
    }

    &:nth-child(3) {
      background-position: -108px 0;
    }

    &:nth-child(4) {
      background-position: -162px 0;
    }
  }

  .title {
    margin: 0 -40px;
    padding: 0 40px;
  }

  .notes {
    margin: 0 -40px;
    padding: 30px 40px;
  }

  .textarea {
    font-size: 18px;
  }

  .label {
    width: 360px;
    margin-bottom: 60px;
  }

  .labelScale {
    width: 130px;
    font-size: 18px;
  }

  .scale {
    background-image: url("/static/images/scale.png");
    margin-top: 35px;
    margin-bottom: 20px;
    width: 425px;
    height: 31px;
  }

  .scale0 {
    background-position: 0 0;
  }

  .scale1 {
    background-position: 0 -32px;
  }

  .scale2 {
    background-position: 0 -64px;
  }

  .scale3 {
    background-position: 0 -96px;
  }

  .scale4 {
    background-position: 0 -128px;
  }
}
