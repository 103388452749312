.definitionList {
  color: #626060;
  font-weight: 300;
  margin-top: 1.25em;
}

.definitionDescription {
  margin-bottom: 1.25em;
}

.definitionListTitle {
  font-weight: 600;
  margin-bottom: 0.25em;
}
