$color-primary: #2cacdc;
$color-primary-1: #148bbc;
$color-primary-2: #2cabdc;
$color-primary-3: #2593bc;
$color-primary-4: #1c99d4;
$color-primary-dark: #08445a;

$color-secondary-dark-1: #171a31;
$color-secondary-dark-2: #3b3e59;
$color-secondary-dark: #070d24;
$color-secondary-dark-hover: lighten($color-secondary-dark, 5%);
$font-primary: 'Roboto', sans-serif;
$font-secondary: 'Open Sans';

$linear: cubic-bezier(0.25, 0.25, 0.75, 0.75);
$ease: cubic-bezier(0.25, 0.1, 0.25, 1);
$ease-in: cubic-bezier(0.42, 0, 1, 1);
$ease-out: cubic-bezier(0, 0, 0.58, 1);
$ease-in-out: cubic-bezier(0.42, 0, 0.58, 1);

$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);

$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);

$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

@mixin center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin container {
  @include center;

  max-width: 80%;

  @media screen and (min-width: 600px) {
    max-width: 500px;
  }
}

@mixin containerLarge {
  @include container;

  max-width: 90%;

  @media screen and (min-width: 768px) {
    min-width: 650px;
    max-width: 50%;
  }
}

@mixin errorLabel {
  font-size: 13px;
  margin: 0.7em 0;
  color: red;
}

@mixin buttonReset {
  background-color: transparent;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  font-family: $font-primary;
  margin: 0;
  padding: 0;
  text-align: center;

  &:focus {
    outline: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@keyframes loading {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@mixin spinAnimation {
  animation: spin 1s $easeInOutCirc infinite;
}

@mixin loadingAnimation {
  animation: loading 0.75s linear infinite;
}
