@import '../../static/styles/variables';

.panel {
  margin: 0 -20px;
  padding: 30px 20px;
  background-color: #f6f6f6;
  font-size: 20px;
  text-align: center;
  font-weight: 300;
  line-height: 1.25;
  color: $color-primary;
}

.period {
  strong {
    font-weight: 700;
  }
}

.dateContainer {
  width: 150px;
}

.error {
  font-size: 12px;
  color: red;
  text-align: left;
  max-width: 300px;
  margin: 5px auto 0;

  animation: fadeIn 100ms ease-in;
}

.form {
  margin-top: 30px;
}

.schedule,
.motivos {
  display: flex;
  max-width: 300px;
  justify-content: space-between;
  margin: 0 auto;

  :global(.DayPickerInput) {
    flex-grow: 1;
    margin-right: 8px;
  }
}

.motivos {
  margin-bottom: 20px;
}

.motivos > div {
  width: 100%;
}

.footer {
  margin: 20px auto 0;
  max-width: 300px;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 600px) {
  .schedule,
  .motivos,
  .error {
    max-width: 350px;
  }

  .panel {
    margin: 0 -40px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .footer {
    max-width: 350px;
  }
}
