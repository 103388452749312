@import "../../static/styles/variables";

.title {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;

  > div {
    margin-right: 20px;
    font-weight: 300;
  }
}

.container {
  margin-top: 30px;
  @include containerLarge;

  h1 {
    height: 100%;
  }
}
